import Tooltip from 'bootstrap/js/dist/tooltip'
import Control from '@peckadesign/pd-naja/dist/utils/Control'

class TooltipControl implements Control {
	public readonly tooltipOptions: Partial<Tooltip.Options> = {
		offset: [0, -3],
		html: true
	}

	public initialize(context: Element | Document): void {
		context
			.querySelectorAll('[data-bs-toggle="tooltip"]:not(.form-switch .form-check-input)')
			.forEach((element) => new Tooltip(element, this.tooltipOptions))

		context
			.querySelectorAll<HTMLInputElement>('.form-switch .form-check-input[data-bs-toggle="tooltip"]')
			.forEach((element) => {
				element.setAttribute('title', element.dataset[element.checked ? 'tooltipOn' : 'tooltipOff'] as string)
				const tooltip = new Tooltip(element, this.tooltipOptions)

				element.addEventListener('change', () => {
					tooltip.hide()

					element.dataset.bsOriginalTitle = element.dataset[element.checked ? 'tooltipOn' : 'tooltipOff']
				})
			})
	}
}

export default new TooltipControl()

import { disableTabAnimation, enableTabAnimation } from '@/js/bootstrap/tab'
import Tab from 'bootstrap/js/dist/tab'
import { Extension, Naja, SuccessEvent } from 'naja/dist/Naja'
import { InteractionEvent } from 'naja/dist/core/UIHandler'

declare module 'naja/dist/Naja' {
	interface Options {
		formActiveTabIds?: FormActiveTabIds
	}
}

type FormActiveTabIds = string[]

export class FormTabsStateExtension implements Extension {
	public static readonly LOCAL_STORAGE_KEY = 'pdFormTabsState'
	private static readonly DISABLE_ANIMATION_CLASS = 'disable-animations'

	public constructor() {
		this.attachHandlers()
		this.restoreFormActiveTabsFromStorage()
	}

	public initialize(naja: Naja) {
		naja.uiHandler.addEventListener('interaction', this.handleInteraction.bind(this))
		naja.addEventListener('success', this.handleSuccess.bind(this))
	}

	private toggleFormActiveTabs(formActiveTabIds: FormActiveTabIds): void {
		if (formActiveTabIds.length === 0) {
			return
		}

		disableTabAnimation()

		formActiveTabIds.forEach((tabId) => {
			const tabTrigger = document.querySelector(`[data-bs-target="${tabId}"]`)

			if (!tabTrigger) {
				return
			}

			const bsTab = Tab.getOrCreateInstance(tabTrigger)
			bsTab.show()
		})

		enableTabAnimation()
	}

	private getFormActiveTabIds(form: HTMLFormElement): FormActiveTabIds {
		// Get triggers for stored tabs; ignore web / web-lang tabs, those are processed in `WebLangStateExtension`
		const activeTabTriggers = form.querySelectorAll<HTMLElement>(
			'[role="tab"][aria-selected="true"]:not([data-tab-web-id], [data-tab-web-lang-id])'
		)
		const activeTabIds: FormActiveTabIds = []

		activeTabTriggers.forEach((tab) => {
			if (tab.dataset.bsTarget) {
				activeTabIds.push(tab.dataset.bsTarget)
			}
		})

		return activeTabIds
	}

	// Ajax forms uses custom option `formActiveTabIds` to store and restore active tabs. This option is handled by Naja
	// callbacks.
	private handleInteraction(event: InteractionEvent): void {
		const { element, options } = event.detail
		const form = (element as any).form

		if (form) {
			options.formActiveTabIds = this.getFormActiveTabIds(form)
		}
	}

	private handleSuccess(event: SuccessEvent): void {
		const { options } = event.detail

		if (options.formActiveTabIds) {
			this.toggleFormActiveTabs(options.formActiveTabIds)
		}
	}

	// Non-ajax forms store the active tab IDs into `localStorage` on `submit` event. They are also restored on class
	// instantiation (page load).
	private attachHandlers(): void {
		const forms = document.querySelectorAll<HTMLFormElement>('form')

		forms.forEach((form) => {
			form.addEventListener('submit', (event: SubmitEvent) => {
				this.storeFormActiveTabsIntoStorage(event.target as HTMLFormElement)
			})
		})
	}

	private restoreFormActiveTabsFromStorage(): void {
		const storageItem = window.localStorage.getItem(FormTabsStateExtension.LOCAL_STORAGE_KEY)

		if (!storageItem) {
			return
		}

		window.localStorage.removeItem(FormTabsStateExtension.LOCAL_STORAGE_KEY)
		const formActiveTabIds = JSON.parse(storageItem) as FormActiveTabIds

		this.toggleFormActiveTabs(formActiveTabIds)
	}

	private storeFormActiveTabsIntoStorage(form: HTMLFormElement): void {
		window.localStorage.setItem(
			FormTabsStateExtension.LOCAL_STORAGE_KEY,
			JSON.stringify(this.getFormActiveTabIds(form))
		)
	}
}

import Sortable, { SortableEvent, SortableOptions } from 'sortablejs'
import Control from '@peckadesign/pd-naja/dist/utils/Control'
import naja from 'naja'

class SortableControl implements Control {
	private ghostElement: Element | null = null

	private readonly sortableOptions: SortableOptions = {
		handle: '.js-sortable__handle',
		dragClass: 'js-sortable__drag',
		ghostClass: 'js-sortable__ghost',
		onSort: this.onSort
	}

	public initialize(context: Element | Document): void {
		const sortable = context.querySelectorAll<HTMLElement>('.js-sortable')

		sortable.forEach((element) => {
			Sortable.create(element, this.sortableOptions)
		})
	}

	private onSort(event: SortableEvent): void {
		const url = event.to.dataset.sortableUrl
		if (!url) {
			return
		}

		const sorted = Array.from(event.to.querySelectorAll<HTMLElement>(':scope > *')).map(
			(item: HTMLElement) => item.dataset.sortableId
		)

		if (sorted.includes(undefined)) {
			throw new Error('Unexpected value of undefined in sorted IDs!')
		}

		naja.makeRequest('post', url, { sorted }, { history: false })
	}
}

export default new SortableControl()

import React, { Fragment, ReactNode } from 'jsx-dom'
import { ConditionalWrappers } from '@/js/helpers/JSXConditionalWrappers'

export interface SpinnerProps {
	size?: number
	strokeWidth?: number
	overlay?: boolean
	showText?: boolean
	marginClass?: string
	text?: string
	textClass?: string
}

export const Spinner = ({
	size = 40,
	strokeWidth = size / 4,
	overlay = false,
	marginClass = overlay ? undefined : 'my-6',
	showText = true,
	text = 'Načítání…',
	textClass = 'h2'
}: SpinnerProps): Element => {
	const radius = `${(size - strokeWidth) / 2}px`
	const style = `--bs-spinner-radius: ${radius}`

	return (
		<div
			className={[
				'spinner d-flex flex-column align-items-center justify-content-center w-100 h-100 text-center',
				marginClass,
				{ 'position-absolute top-0 start-0 bg-white bg-opacity-90': overlay }
			]}
			style={style}
		>
			<ConditionalWrappers
				wrappers={[
					overlay && ((children: ReactNode) => <div className="spinner__sticky">{children}</div>),
					overlay && ((children: ReactNode) => <div className="spinner__spinner-wrap">{children}</div>)
				]}
			>
				<Fragment>
					<svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} className="spinner__spinner">
						<circle
							className="spinner__circle spinner__circle--bg"
							strokeWidth={strokeWidth}
							fill="transparent"
							r={radius}
							cx={size / 2}
							cy={size / 2}
						/>
						<circle
							className="spinner__circle spinner__circle--fg"
							strokeWidth={strokeWidth}
							fill="transparent"
							r={radius}
							cx={size / 2}
							cy={size / 2}
						/>
					</svg>
					{showText && (
						<div className="spinner__text mt-3">
							<span className={textClass}>{text}</span>
						</div>
					)}
				</Fragment>
			</ConditionalWrappers>
		</div>
	)
}

export const getSpinnerProps = (element: Element): SpinnerProps => {
	const spinnerSize = element.getAttribute('data-naja-spinner-size')
	const showText = !element.hasAttribute('data-naja-spinner-hide-text')

	return {
		size: spinnerSize ? parseInt(spinnerSize) : undefined,
		overlay: true,
		showText,
		text: element.getAttribute('data-naja-spinner-text') || undefined
	}
}

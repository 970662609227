import Modal from 'bootstrap/js/dist/modal'
import React from 'jsx-dom'
import { Spinner } from '@/js/App/Spinner'
import { AjaxModal } from '@peckadesign/pd-naja/dist/extensions/AjaxModalExtension'

type OptionWidth = string | null
type OptionClassName = string | null

interface Options {
	width: OptionWidth
	className: OptionClassName
}

export class BootstrapAjaxModal implements AjaxModal {
	public readonly reservedSnippetIds = ['snippet--pdModal', 'snippet--pdModalHeader']

	public readonly element: Element = (
		<div class="modal fade">
			<div class="modal-dialog modal-dialog-centered modal-lg">
				<div class="modal-content ajax-wrap">
					<div class="modal-header">
						<div id="snippet--pdModalHeader">
							<h1>&nbsp;</h1>
						</div>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body bg-light p-0" id="snippet--pdModal"></div>
				</div>
			</div>
		</div>
	)

	private spinnerElement: Element = Spinner({})

	private modal: Modal
	private placeholder: HTMLElement
	private dialog: HTMLElement
	private content: HTMLElement
	private header: HTMLElement
	private readonly sizes = ['sm', 'md', 'lg', 'xl']
	private readonly modalAllowedClassName = ['modal', 'fade', 'show', 'hide']

	public constructor() {
		this.modal = new Modal(this.element)

		const placeholder = document.getElementById('pdModal-placeholder')
		const dialog = this.element.querySelector('.modal-dialog')
		const content = this.element.querySelector('.modal-body')
		const header = this.element.querySelector('#snippet--pdModalHeader')

		if (dialog === null || content === null || header === null) {
			throw new Error('Invalid modal template.')
		}

		if (placeholder === null) {
			throw new Error('Missing placeholder element for modal.')
		}

		this.placeholder = placeholder
		this.dialog = dialog as HTMLElement
		this.content = content as HTMLElement
		this.header = header as HTMLElement

		this.element.addEventListener('hidden.bs.modal', () => {
			this.placeholder.removeChild(this.element as Element)

			this.resetWidth()
		})

		this.element.addEventListener('show.bs.modal', () => {
			this.content.innerHTML = ''
			this.content.appendChild(this.spinnerElement)

			this.header.innerHTML = '<h1>&nbsp;</h1>'

			this.placeholder.append(this.element as Element)
		})
	}

	public isShown(): boolean {
		// @ts-ignore
		return this.modal._isShown
	}

	public show(): void {
		this.modal.show()
	}

	public hide(): void {
		this.modal.hide()
	}

	public onShow(callback: EventListener): void {
		this.element.addEventListener('show.bs.modal', callback)
	}

	public onHide(callback: EventListener): void {
		this.element.addEventListener('hide.bs.modal', callback)
	}

	public onHidden(callback: EventListener): void {
		this.element.addEventListener('hidden.bs.modal', callback)
	}

	public getOptions(element: Element): Options {
		return {
			width: this.getWidth(element),
			className: this.getClassName(element)
		}
	}

	public setOptions(options: Options): void {
		this.setWidth(options.width)
		this.setClassName(options.className)

		this.modal.handleUpdate()
	}

	private getWidth = (element: Element): OptionWidth => {
		return element.getAttribute('data-naja-modal-width') || history.state?.pdModal?.options?.width || null
	}

	private setWidth(width: OptionWidth): void {
		this.resetWidth()

		if (width) {
			this.dialog.classList.add(`modal-${width}`)
		}
	}

	private getClassName(element: Element): OptionClassName {
		return element.getAttribute('data-naja-modal-class-name')
	}

	private setClassName(className: OptionClassName): void {
		this.resetClassName()

		if (className) {
			this.element.classList.add(className)
		}
	}

	private resetWidth(): void {
		this.dialog.classList.remove(...this.sizes.map((size) => `modal-${size}`))
	}

	private resetClassName(): void {
		const removeClass = Array.from(this.element.classList).filter(
			(className) => !this.modalAllowedClassName.includes(className)
		)

		this.element.classList.remove(...removeClass)
	}
}

import Control from '@peckadesign/pd-naja/dist/utils/Control'
import { DetectScrollPosition } from '@/js/App/DetectScrollPosition'

class DetectScrollPositionControl implements Control {
	public initialize(context: Element | Document): void {
		const elements = context.querySelectorAll<HTMLElement>('.js-scroll-detect')
		elements.forEach((element) => new DetectScrollPosition(element))
	}
}

export default new DetectScrollPositionControl()

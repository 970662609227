import { disableTabAnimation, enableTabAnimation } from '@/js/bootstrap/tab'
import Tab from 'bootstrap/js/dist/tab'
import { Extension, Naja } from 'naja/dist/Naja'
import { getCookie, setCookie } from 'typescript-cookie'

type WebLangTabsState = {
	webId?: string
	webLangId?: string
}

export class WebLangTabsStateExtension implements Extension {
	public static readonly COOKIE_NAME = 'pdWebLangTabsState'
	private static readonly DISABLE_ANIMATION_CLASS = 'disable-animations'

	public state: WebLangTabsState | undefined

	private effectiveDomain

	public constructor() {
		// Restore before attaching the handler. This ensures that the restore process doesn't override the state (for
		// example, if the restore process finds only the web tab and not the web-lang tab).
		this.state = this.restoreStateFromCookie()
		this.toggleActiveWebLangTab()

		this.effectiveDomain = this.getEffectiveDomain()

		this.attachHandlers()
	}

	public initialize(naja: Naja) {
		naja.addEventListener('success', this.toggleActiveWebLangTab.bind(this))
	}

	private attachHandlers(): void {
		document.addEventListener('show.bs.tab', this.onTabShow.bind(this))
	}

	private onTabShow(event: Event): void {
		const newTab = event.target as HTMLElement

		if (newTab.dataset.tabWebId === undefined && newTab.dataset.tabWebLangId === undefined) {
			return
		}

		this.state = { webId: newTab.dataset.tabWebId, webLangId: newTab.dataset.tabWebLangId }
		this.storeStateIntoCookie()
	}

	private toggleActiveWebLangTab(): void {
		if (!this.state) {
			return
		}

		const tabTrigger = this.getWebLangTabTrigger()

		if (!tabTrigger) {
			return
		}

		disableTabAnimation()

		const bsTab = Tab.getOrCreateInstance(tabTrigger)
		bsTab.show()

		enableTabAnimation()
	}

	private getWebLangTabTrigger(): HTMLElement | null {
		if (!this.state) {
			return null
		}

		let tabTrigger = null

		if (this.state.webLangId) {
			tabTrigger = document.querySelector<HTMLElement>(`[data-tab-web-lang-id="${this.state.webLangId}"]`)
		}

		if (!tabTrigger && this.state.webId) {
			tabTrigger = document.querySelector<HTMLElement>(`[data-tab-web-id="${this.state.webId}"]`)
		}

		return tabTrigger
	}

	private restoreStateFromCookie(): WebLangTabsState | undefined {
		const cookie = getCookie(WebLangTabsStateExtension.COOKIE_NAME)

		if (!cookie) {
			return
		}

		return JSON.parse(cookie) as WebLangTabsState
	}

	private storeStateIntoCookie(): void {
		setCookie(WebLangTabsStateExtension.COOKIE_NAME, JSON.stringify(this.state), {
			domain: this.effectiveDomain,
			path: '/',
			expires: 365
		})
	}

	private getEffectiveDomain() {
		const parts = location.hostname.split('.')

		if (parts.length > 2) {
			// For domains with more than two parts, strip the first part
			return parts.slice(1).join('.')
		}

		// If the hostname has two or fewer parts, return it as is
		return location.hostname
	}
}

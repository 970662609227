import Control from '@peckadesign/pd-naja/dist/utils/Control'
import { DatePicker } from '@/js/App/DatePicker'

class DatepickerControl implements Control {
	private readonly selector = '.js-datepicker'

	public initialize(context: Element | Document) {
		const inputs = context.querySelectorAll<HTMLElement>(this.selector)

		inputs.forEach((input) => new DatePicker(input, this.selector))
	}
}

export default new DatepickerControl()

interface HTMLFormElement {
	originalFormData: FormData
	isChanged(part?: HTMLElement): boolean
}

interface FormKeys {
	current: string[]
	original: string[]
}

class FormIsChanged {
	public static ignoreSelector?: string

	public constructor(ignoreSelector?: string) {
		FormIsChanged.ignoreSelector = ignoreSelector

		// Method returns boolean based on form being changed. While the `HTMLFormElement.originalFormData` contains
		// even the values of disabled element, this method ignores all currently disabled elements. Therefore, even if
		// any of currently disabled elements has a different value than on initialization, method still can return
		// `false` as form not being changed.
		HTMLFormElement.prototype.isChanged = function (part?: HTMLElement): boolean {
			return FormIsChanged.isChanged.call(this, part)
		}

		// Lazy evaluated property containing `FormData` for initial state of the form, including disabled elements.
		Object.defineProperty(HTMLFormElement.prototype, 'originalFormData', {
			get: FormIsChanged.getOriginalFormData
		})
	}

	private static isChanged(this: HTMLFormElement, part?: HTMLElement): boolean {
		const currentFormData = new FormData(this)
		const originalFormData = this.originalFormData

		let keys: FormKeys = {
			current: FormIsChanged.getKeys.call(this, currentFormData),
			original: FormIsChanged.getKeys.call(this, originalFormData)
		}

		if (FormIsChanged.ignoreSelector) {
			keys = FormIsChanged.filterKeysBySelector.call(this, keys)
		}

		if (part) {
			keys = FormIsChanged.filterKeysByPart.call(this, part, keys)
		}

		if (keys.current.length !== keys.original.length) {
			return true
		}

		for (const key of keys.current) {
			const currentValues = currentFormData.getAll(key)
			const originalValues = originalFormData.getAll(key)

			if (currentValues.length !== originalValues.length) {
				return true
			}

			if (currentValues.some(FormIsChanged.isUnequal, originalValues)) {
				return true
			}
		}

		return false
	}

	private static getKeys(this: HTMLFormElement, formData: FormData): string[] {
		return Array.from(formData.keys())
	}

	private static isNamedItemIgnoredBySelector(this: HTMLFormElement, key: string): boolean {
		let item = this.elements.namedItem(key)

		if (item instanceof RadioNodeList) {
			item = item.item(0) as HTMLInputElement
		}

		return item !== null && !item.matches(FormIsChanged.ignoreSelector as string)
	}

	private static filterKeysBySelector(this: HTMLFormElement, keys: FormKeys): FormKeys {
		keys.current = keys.current.filter(FormIsChanged.isNamedItemIgnoredBySelector.bind(this))
		keys.original = keys.original.filter(FormIsChanged.isNamedItemIgnoredBySelector.bind(this))

		return keys
	}

	private static filterKeysByPart(this: HTMLFormElement, part: HTMLElement, keys: FormKeys): FormKeys {
		keys.current = keys.current.filter((key) => {
			const item = this.elements.namedItem(key)

			// In case of RadioNodeList, we assume, that they are either all in or all out of the `part`, so we only
			// check the first one.
			return part.contains(item instanceof RadioNodeList ? item.item(0) : item)
		})

		keys.original = keys.original.filter((key) => {
			const item = this.elements.namedItem(key)
			return (
				item &&
				!('disabled' in item && (item as any).disabled) &&
				part.contains(item instanceof RadioNodeList ? item.item(0) : item)
			)
		})

		return keys
	}

	private static isUnequal(this: FormDataEntryValue[], value: FormDataEntryValue, index: number) {
		return value !== this[index]
	}

	private static getOriginalFormData(this: HTMLFormElement): any {
		if (this._originalFormData) {
			return this._originalFormData
		}

		const clonedForm = this.cloneNode(true) as HTMLFormElement

		// We need to get values even for disabled elements → make them enabled in clone
		Array.from(clonedForm.elements).forEach((element) => {
			if ('disabled' in element) {
				;(element as any).disabled = false
			}
		})

		// Avoid masking by calling prototype reset, https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/reset
		HTMLFormElement.prototype.reset.call(clonedForm)

		this._originalFormData = new FormData(clonedForm)

		return this._originalFormData
	}
}

new FormIsChanged('[data-contenteditor]')

import Popover from 'bootstrap/js/dist/popover'
import Control from '@peckadesign/pd-naja/dist/utils/Control'
import { Options } from '@popperjs/core'

class PopoverControl implements Control {
	public readonly popoverOptions: Partial<Popover.Options> = {
		offset: [0, 5],
		html: true,
		trigger: 'hover focus',
		placement: 'top',
		fallbackPlacements: ['top', 'bottom'],

		// Using object as a `popperConfig` breaks arrow positioning, so we are using function to modify the default
		// bootstrap configuration object as documented in
		// https://getbootstrap.com/docs/5.1/components/popovers/#using-function-with-popperconfig
		//
		// Note that `defaultBsPopperConfig` is not the type `Popover.Options` as stated in @types/bootstrap, but it is
		// `Options` from Popper.js library, therefore ts-ignore is required.
		// @ts-ignore
		popperConfig: (defaultBsPopperConfig: Options) => {
			const preventOverflowModifier = defaultBsPopperConfig.modifiers.find(
				(modifier) => modifier.name === 'preventOverflow'
			)

			if (preventOverflowModifier && preventOverflowModifier.options !== undefined) {
				preventOverflowModifier.options.padding = 5
			}

			return defaultBsPopperConfig
		}
	}

	public initialize(context: Element | Document): void {
		context.querySelectorAll('[data-bs-toggle="popover"]').forEach((element) => {
			new Popover(element, this.popoverOptions)

			// When popover is used inside `label` associated with `radio` or `checkbox`, this prevents clicking inside
			// popover to change the input's state.
			element.addEventListener('click', (event: Event) => {
				const target = event.target as HTMLElement

				if (target.closest('label') && target.closest('.popover') && !target.closest('a')) {
					event.preventDefault()
				}
			})
		})
	}
}

export default new PopoverControl()

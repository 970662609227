import 'bootstrap/js/dist/tab'

const DISABLE_TAB_ANIMATION_CLASS = 'disable-animations'

export function disableTabAnimation() {
	document.documentElement.classList.add(DISABLE_TAB_ANIMATION_CLASS)
}

export function enableTabAnimation() {
	const callback = () => document.documentElement.classList.remove(DISABLE_TAB_ANIMATION_CLASS)

	if (window.requestIdleCallback !== undefined) {
		requestIdleCallback(callback, { timeout: 1000 })
	} else {
		setTimeout(callback, 0)
	}
}

import 'bootstrap/js/dist/collapse'

const toggleNavbarClass = (event: Event) => {
	const collapseElement = event.target as Element

	if (!collapseElement.classList.contains('navbar-collapse')) {
		return
	}

	const navbar = collapseElement.closest('.navbar')

	navbar?.classList[event.type === 'show.bs.collapse' ? 'add' : 'remove']('is-expanded')
}

document.addEventListener('show.bs.collapse', toggleNavbarClass)
document.addEventListener('hide.bs.collapse', toggleNavbarClass)

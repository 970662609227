import Control from '@peckadesign/pd-naja/dist/utils/Control'
import Dropzone, { DropzoneOptions } from 'dropzone'
import naja from 'naja'

class UploadControl implements Control {
	private readonly paramName = 'files'

	public initialize(context: Element | Document) {
		context.querySelectorAll('.js-dropzone').forEach((element) => {
			const form = element.querySelector<HTMLFormElement>('.dropzone')
			const previewElement = element.querySelector<HTMLElement>('.js-dropzone__preview')

			if (!form) {
				return
			}

			const dropzone = new Dropzone(form, {
				maxFilesize: form.dataset.dzMaxFileSize ? Number(form.dataset.dzMaxFileSize) / 1024 / 1024 : undefined,
				acceptedFiles: form.dataset.dzAcceptedFiles,
				paramName: form.dataset.dzParamName,

				uploadMultiple: true,
				parallelUploads: 1,

				...this.getDict(form),

				// @types/dropzone are not ready for dropzone 6.0.0-beta2, so this option is not recognized
				// @ts-ignore
				disablePreviews: !previewElement,
				previewsContainer: previewElement ?? undefined,
				thumbnailWidth: 70,
				thumbnailHeight: 70,

				addRemoveLinks: true
			})

			dropzone.on('successmultiple', (files: Dropzone.DropzoneFile[], responseText: any) => {
				files.forEach((file) => {
					file.previewElement.remove()
				})

				if (typeof responseText === 'object' && 'snippets' in responseText) {
					naja.snippetHandler.updateSnippets(responseText.snippets)
				}
			})
		})
	}

	private getDict(form: HTMLFormElement): Partial<DropzoneOptions> {
		const dict: Partial<DropzoneOptions> = {}

		dict.dictDefaultMessage = form.dataset.dzDictDefaultMessage
		dict.dictFallbackMessage = form.dataset.dzDictFallbackMessage
		dict.dictFallbackText = form.dataset.dzDictFallbackText
		dict.dictFileTooBig = form.dataset.dzDictFileTooBig
		dict.dictInvalidFileType = form.dataset.dzDictInvalidFileType
		dict.dictResponseError = form.dataset.dzDictResponseError
		dict.dictCancelUpload = form.dataset.dzDictCancelUpload
		dict.dictCancelUploadConfirmation = form.dataset.dzDictCancelUploadConfirmation
		dict.dictRemoveFile = form.dataset.dzDictRemoveFile
		dict.dictUploadCanceled = form.dataset.dzDictUploadCanceled

		return dict
	}
}

export default new UploadControl()

import naja from 'naja'
import TomSelect from 'tom-select/dist/esm/tom-select'

export class DependentSelectBox {
	private select: TomSelect
	private dependentSelectBox: TomSelect
	private handler: string

	public constructor(dependentSelectBox: TomSelect, select: TomSelect) {
		this.dependentSelectBox = dependentSelectBox
		this.select = select

		const handler = dependentSelectBox.input.dataset.dependentSelectBoxHandler
		if (handler === undefined) {
			throw new Error('Missing select box handler')
		} else {
			this.handler = handler
			this.select.input.addEventListener('change', this.onChange.bind(this))
		}
	}

	private onChange() {
		const selectedValue = this.select.input.value

		const request = naja.makeRequest('get', this.handler, { value: selectedValue }, { history: false })
		const dependentSelectBox = this.dependentSelectBox

		dependentSelectBox.clear(true)
		dependentSelectBox.clearOptions()

		request.then(function (values) {
			let hasOptions = false
			for (const key in values) {
				dependentSelectBox.addOption({ value: key, text: values[key] }, false)
				hasOptions = true
			}

			if (hasOptions) {
				dependentSelectBox.refreshOptions()
				dependentSelectBox.refreshItems()
			}
		})
	}
}

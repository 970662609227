import Tab from 'bootstrap/js/dist/tab'

type HTMLFormFieldElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLButtonElement

type NetteFormsError = {
	element: HTMLFormFieldElement
	message: string
}

// Load netteForms without initialization
let Nette

// eslint-disable-next-line @typescript-eslint/no-unused-vars
window.Nette = Nette = require('nette-forms')

// Load custom validators
require('pd-forms/src/assets/validators/pdForms.validator.netteRuleProxy')
require('pd-forms/src/assets/validators/pdForms.validator.phone')

// Load pdForms
// eslint-disable-next-line @typescript-eslint/no-var-requires
const pdForms = require('pd-forms/src/assets/pdForms')

// Redefine class names for use with bootstrap
pdForms.constants.MESSAGE_ERROR = 'invalid'
pdForms.getMessageClassName = (isGlobal: boolean, tagName: string, type: string) => {
	return tagName === 'p' ? `message message--${type}` : `${type}-feedback`
}

// Add status classes directly on input
const addMessage: (
	elem: HTMLFormFieldElement,
	message: string,
	type: string,
	isAjaxRuleMessage: boolean,
	escapeMessage: boolean
) => void = pdForms.addMessage
const removeMessages: (elem: HTMLFormFieldElement, removeAjaxRulesMessages: boolean) => void = pdForms.removeMessages

pdForms.addMessage = (
	elem: HTMLFormFieldElement,
	message: string,
	type: string,
	isAjaxRuleMessage: boolean,
	escapeMessage: boolean
): void => {
	addMessage(elem, message, type, isAjaxRuleMessage, escapeMessage)

	if (elem.type === 'radio' && elem.form) {
		elem.form[elem.name].forEach((radio: HTMLInputElement) => radio.classList.add(`is-${type}`))
	} else {
		elem.classList.add(`is-${type}`)
	}
}

pdForms.removeMessages = (elem: HTMLFormFieldElement, removeAjaxRulesMessages: boolean): void => {
	removeMessages(elem, removeAjaxRulesMessages)

	for (const i in pdForms.constants) {
		if (elem.type === 'radio' && elem.form) {
			elem.form[elem.name].forEach((radio: HTMLInputElement) => radio.classList.remove(`is-${pdForms.constants[i]}`))
		} else {
			elem.classList.remove(`is-${pdForms.constants[i]}`)
		}
	}
}

// Do not validate fields from content editor
const liveValidation: (event: Event) => void = pdForms.liveValidation
pdForms.liveValidation = (event: Event): void => {
	if (event.target instanceof HTMLElement && 'contenteditor' in event.target.dataset) {
		return
	}

	liveValidation(event)
}

// Handle Bootstrap Tabs inside Form. When error is on another tab, we need to switch the tab first and only then we
// can focus the form element with error.
const switchToTabAndFocusError = (tab: HTMLElement, focusElem: HTMLFormFieldElement): void => {
	const tabTrigger = document.querySelector(`[data-bs-target="#${tab.id}"]`)

	if (!tabTrigger) {
		return
	}

	tabTrigger.addEventListener(
		'shown.bs.tab',
		() => {
			focusElem.focus()
		},
		{ once: true }
	)

	const bsTab = Tab.getOrCreateInstance(tabTrigger)
	bsTab.show()
}

window.Nette.showFormErrors = (form: HTMLFormElement, errors: NetteFormsError[]): void => {
	if (errors.length === 0) {
		return
	}

	const focusElem = errors[0].element
	const tabWithError = focusElem.closest<HTMLElement>('.tab-pane')

	if (tabWithError && !tabWithError.classList.contains('active')) {
		switchToTabAndFocusError(tabWithError, focusElem)
	} else {
		focusElem.focus()
	}
}

// Set global variable
window.pdForms = pdForms

// Initialize netteForms
Nette.initOnLoad()

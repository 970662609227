import ColorInput from '@/js/App/ColorInput'
import Control from '@peckadesign/pd-naja/dist/utils/Control'

class ColorInputControl implements Control {
	private readonly selector = '.js-color-input'

	public initialize(context: Element | Document): void {
		const colorInputs = context.querySelectorAll(this.selector)

		colorInputs.forEach((fileInput) => new ColorInput(fileInput, this.selector))
	}
}

export default new ColorInputControl()

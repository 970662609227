import TooltipControl from '@/js/Controls/TooltipControl'
import Tooltip from 'bootstrap/js/dist/tooltip'

export default class FileInput {
	private input: HTMLInputElement
	private clearButton?: Node

	public constructor(element: Element) {
		const input = element.querySelector('[type="file"]')
		const template = element.querySelector('template')

		if (!input || !template) {
			throw new Error('Invalid file input template.')
		}

		this.input = input as HTMLInputElement
		this.clearButton = template?.content?.firstElementChild?.cloneNode(true)
		this.clearButton?.addEventListener('click', this.clearInput.bind(this))

		this.input.addEventListener('change', this.handleChange.bind(this))
		this.input.addEventListener('keydown', this.handleKeydown.bind(this))
	}

	private handleChange(): void {
		this.input.value ? this.showClearButton() : this.hideClearButton()
	}

	private handleKeydown(event: KeyboardEvent): void {
		switch (event.key) {
			case 'Delete':
			case 'Backspace':
				this.clearInput(event)
				break
		}
	}
	private clearInput(event: Event): void {
		event.preventDefault()

		this.input.value = ''
		this.input.dispatchEvent(new Event('change', { bubbles: true }))
	}

	private showClearButton(): void {
		if (!this.clearButton || this.clearButton.isConnected) {
			return
		}

		this.input.after(this.clearButton)
		Tooltip.getOrCreateInstance(this.clearButton as HTMLButtonElement, TooltipControl.tooltipOptions)
	}

	private hideClearButton(): void {
		if (!this.clearButton || !this.clearButton.isConnected) {
			return
		}

		Tooltip.getInstance(this.clearButton as HTMLButtonElement)?.hide()
		;(this.clearButton as HTMLButtonElement).remove()
	}
}

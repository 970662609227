import { ReactNode } from 'jsx-dom'

type ConditionalWrapper = false | ((children: ReactNode) => ReactNode)

interface ConditionalWrappersProps {
	wrappers: ConditionalWrapper[]
	children: ReactNode
}

export const ConditionalWrappers = ({ wrappers, children }: ConditionalWrappersProps) =>
	wrappers.reduceRight(
		(children: ReactNode, wrapper: ConditionalWrapper) => (wrapper ? wrapper(children as ReactNode) : children),
		children
	) as HTMLElement

/**
 * Returns filtered mutationList, where every attribute change is present only once - its last state is preserved,
 * all other changes of this attribute are ignored. Non-attribute mutations are discarded.
 */
interface uniqueMutation {
	target: Node
	attr: string
}

export default function filterMutationAttributesList(mutationList: MutationRecord[]): MutationRecord[] {
	const processedMutations: uniqueMutation[] = []

	return mutationList.reverse().filter((mutation) => {
		if (mutation.type !== 'attributes' || !mutation.attributeName) {
			return false
		}

		if (processedMutations.includes({ target: mutation.target, attr: mutation.attributeName })) {
			return false
		} else {
			processedMutations.push({ target: mutation.target, attr: mutation.attributeName })
			return true
		}
	})
}

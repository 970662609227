import ColorInputControl from '@/js/Controls/ColorInputControl'
import { controlManager } from '@peckadesign/pd-naja'
import BottomStuck from '@/js/Controls/BottomStuck'
import DatepickerControl from '@/js/Controls/DatepickerControl'
import DependentSelectBoxControl from '@/js/Controls/DependentSelectBoxControl'
import DetectScrollPositionControl from '@/js/Controls/DetectScrollPositionControl'
import FileInputControl from '@/js/Controls/FileInputControl'
import PopoverControl from '@/js/Controls/PopoverControl'
import SaveTabContainerControl from '@/js/Controls/SaveTabContainerControl'
import SortableControl from '@/js/Controls/SortableControl'
import TomSelectControl from '@/js/Controls/TomSelectControl'
import TooltipControl from '@/js/Controls/TooltipControl'
import UploadControl from '@/js/Controls/UploadControl'

controlManager.addControlOnLive(BottomStuck)
controlManager.addControlOnLive(ColorInputControl)
controlManager.addControlOnLive(DatepickerControl)
controlManager.addControlOnLive(DetectScrollPositionControl)
controlManager.addControlOnLive(FileInputControl)
controlManager.addControlOnLive(PopoverControl)
controlManager.addControlOnLive(SaveTabContainerControl)
controlManager.addControlOnLive(SortableControl)
controlManager.addControlOnLive(TomSelectControl)
controlManager.addControlOnLive(DependentSelectBoxControl) // DependentSelectBoxControl is dependent on TomSelectControl, it must be loaded after TomSelect
controlManager.addControlOnLive(TooltipControl)
controlManager.addControlOnLive(UploadControl)

if (document.readyState === 'loading') {
	document.addEventListener('DOMContentLoaded', () => controlManager.onLoad())
} else {
	controlManager.onLoad()
}

import { Core, easepick } from '@easepick/core'
import { IPickerConfig } from '@easepick/core/dist/types'
import { BasePlugin } from '@easepick/base-plugin'
import { RangePlugin } from '@easepick/range-plugin'
import { DateTime } from '@easepick/datetime'
import filterMutationAttributesList from '@/js/helpers/filterMutationAttributesList'
import { Class } from '@/js/types/Class'

import pickerCss from '!!file-loader?{"name":"[path][name].[contenthash:8].css"}!postcss-loader?{"postcssOptions":{"plugins":{"autoprefixer":{}}}}!sass-loader!@/styles/easepick.scss'

interface InputPair {
	input: HTMLInputElement
	originalInput: HTMLInputElement
}

const previousMonthIcon =
	'<svg xmlns="http://www.w3.org/2000/svg" fill="none" width="20" height="20" viewBox="0 0 20 20">' +
	'    <path fill="#1E1F1C" d="M10.83 12.77v.3l-1 1.01-3.75-3.78v-.52L9.82 6l1.01 1.02v.29L8.58 9.2H14v1.65H8.58l2.25 1.91Z"/>' +
	'</svg>'

const nextMonthIcon =
	'<svg xmlns="http://www.w3.org/2000/svg" fill="none" width="20" height="20" viewBox="0 0 20 20">' +
	'    <path fill="#1E1F1C" d="M9.25 12.77v.3l1 1.01L14 10.3v-.52L10.26 6 9.25 7.02v.29l2.25 1.9H6.08v1.65h5.42l-2.25 1.91Z"/>' +
	'</svg>'

export class DatePicker {
	private readonly outputFormat = 'YYYY-MM-DD'
	private readonly defaultViewFormat = 'D. M. YYYY'

	private viewFormat: string
	private lang: string
	private isRange: boolean
	private inputs: InputPair[]

	public easepick: Core

	private onFocusBinded: (event: Event) => void

	public constructor(context: HTMLElement, selector: string) {
		const originalInputs = context.querySelectorAll<HTMLInputElement>(`${selector}__input`)

		if (!originalInputs) {
			throw new Error(`Missing input element with class '${selector}__input'`)
		}

		this.inputs = []
		originalInputs.forEach((originalInput) => {
			this.inputs.push({
				input: this.createAlternativeInput(originalInput),
				originalInput
			})
		})

		this.initializeDom()

		this.viewFormat = this.inputs[0].originalInput.dataset.format || this.defaultViewFormat
		this.lang = document.documentElement.lang
		this.onFocusBinded = this.onFocus.bind(this)

		const options = this.getOptions(context)

		this.isRange = !!options.RangePlugin
		this.easepick = new easepick.create(options)

		const observer = new MutationObserver(this.syncInputAttributes.bind(this))
		this.inputs.forEach((inputPair) => {
			observer.observe(inputPair.originalInput, { attributes: true })
		})
	}

	private initializeDom(): void {
		this.inputs.forEach((inputPair) => {
			inputPair.originalInput.insertAdjacentElement('afterend', inputPair.input)
			inputPair.originalInput.setAttribute('hidden', 'true')
		})

		this.alterLabelsFor()
	}

	private createAlternativeInput(input: HTMLInputElement): HTMLInputElement {
		const altInput = input.cloneNode() as HTMLInputElement

		altInput.name += '-alt'
		altInput.id += '-alt'

		altInput.type = 'text'
		altInput.autocomplete = 'off'

		altInput.removeAttribute('data-nette-rules')

		altInput.addEventListener('validate', this.voidEvent)
		altInput.addEventListener('focusout', this.voidEvent)

		return altInput
	}

	private alterLabelsFor(): void {
		this.inputs.forEach((inputPair) => {
			if (!inputPair.originalInput.labels) {
				return
			}

			inputPair.originalInput.labels.forEach((label: HTMLLabelElement) => {
				label.setAttribute('for', inputPair.originalInput.id)
			})
		})
	}

	private getOptions(context: HTMLElement): IPickerConfig {
		const plugins: Class<BasePlugin>[] = []
		const isRange = 'datepickerRange' in context.dataset

		const startDate = this.inputs[0].input.value
			? new DateTime(this.inputs[0].input.value, this.outputFormat)
			: undefined

		const options: IPickerConfig = {
			element: this.inputs[0].input,
			lang: this.lang,
			format: this.viewFormat,
			readonly: false,
			grid: isRange ? 2 : 1,
			calendars: isRange ? 2 : 1,
			date: startDate,
			css: [pickerCss],
			setup: (picker: Core) => {
				this.bindInputCallbacks()

				picker.on('select', this.onSelect.bind(this))
			},
			plugins: plugins,
			locale: {
				previousMonth: previousMonthIcon,
				nextMonth: nextMonthIcon
			}
		}

		if (isRange) {
			const endDate = this.inputs[1].input.value
				? new DateTime(this.inputs[1].input.value, this.outputFormat)
				: undefined

			plugins.push(RangePlugin)

			options.RangePlugin = {
				elementEnd: this.inputs[1].input,
				locale: {
					one: 'den',
					few: 'dny',
					other: 'dnů'
				},
				startDate,
				endDate
			}
		}

		return options
	}

	private bindInputCallbacks(): void {
		this.inputs.forEach((inputPair) => {
			inputPair.input.addEventListener('focus', this.onFocusBinded)
			inputPair.input.addEventListener('input', this.onInput.bind(this))
			inputPair.input.addEventListener('change', () => {
				this.syncInputValue(inputPair.input, inputPair.originalInput)
			})
		})
	}

	// Synchronizes value from visible input to original.
	private syncInputValue(source: HTMLInputElement, target: HTMLInputElement): void {
		if (this.isDateValid(source.value)) {
			const date = new DateTime(source.value, this.viewFormat, this.lang)
			target.value = date.format(this.outputFormat)
		} else if (source.value !== '') {
			target.value = source.value
		} else {
			target.value = ''
		}

		target.dispatchEvent(new Event('change', { bubbles: true }))
		target.dispatchEvent(new CustomEvent('validate', { bubbles: true }))
	}

	// Synchronizes attribute changes from original input to visible one.
	private syncInputAttributes(mutationList: MutationRecord[]): void {
		const filteredMutationList = filterMutationAttributesList(mutationList)

		filteredMutationList.forEach((mutation) => {
			const originalInput = mutation.target as HTMLInputElement
			const input = this.getAltInputForOriginalInput(originalInput)

			if (!mutation.attributeName) {
				return
			}

			const value = originalInput.getAttribute(mutation.attributeName)

			if (value) {
				input.setAttribute(mutation.attributeName, value)
			} else {
				input.removeAttribute(mutation.attributeName)
			}
		})
	}

	private onFocus(event: Event): void {
		const input = event.target as HTMLInputElement
		const originalInput = this.getOriginalInputForAltInput(input)

		originalInput.setAttribute('data-pdforms-ever-focused', 'true')
		input.removeEventListener('focus', this.onFocusBinded)
	}

	private onInput(event: Event): void {
		const input = event.target as HTMLInputElement
		const originalInput = this.getOriginalInputForAltInput(input)

		if (this.isDateValid(input.value)) {
			if (this.isRange) {
				this.easepick[this.inputs[0].input === input ? 'setStartDate' : 'setEndDate'](input.value)
			} else {
				this.easepick.setDate(input.value)
			}

			this.easepick.gotoDate(input.value)
		}

		this.syncInputValue(input, originalInput)
	}

	private onSelect(): void {
		this.inputs.forEach((inputPair) => {
			this.syncInputValue(inputPair.input, inputPair.originalInput)
		})
	}

	private getOriginalInputForAltInput(altInput: HTMLInputElement): HTMLInputElement {
		const inputPair = this.inputs.find((inputPair) => inputPair.input === altInput)

		return (inputPair as InputPair).originalInput
	}

	private getAltInputForOriginalInput(originalInput: HTMLInputElement): HTMLInputElement {
		const inputPair = this.inputs.find((inputPair) => inputPair.originalInput === originalInput)

		return (inputPair as InputPair).input
	}

	/**
	 * Checks if user inputted value is a valid date in the sense of if the value parsed by DateTime plugin is the same
	 * date as user typed.
	 */
	private isDateValid(date: string): boolean {
		if (date === '') {
			return false
		}

		const parsedDate = new DateTime(date, this.viewFormat)

		return date === parsedDate.format(this.viewFormat) || date === parsedDate.format(this.viewFormat).replace(/\s/g, '')
	}

	private voidEvent(event: Event): void {
		event.stopImmediatePropagation()
	}
}

export class DetectScrollPosition {
	private element: HTMLElement
	private startSentinel: HTMLElement | null
	private endSentinel: HTMLElement | null

	public constructor(element: HTMLElement) {
		this.element = element

		this.startSentinel = this.element.querySelector<HTMLElement>('.js-scroll-detect__sentinel-start')
		this.endSentinel = this.element.querySelector<HTMLElement>('.js-scroll-detect__sentinel-end')

		const observer = new IntersectionObserver(this.handleIntersectionChange.bind(this), {
			root: this.element,
			threshold: [1, 0]
		})

		if (this.startSentinel) {
			observer.observe(this.startSentinel)
		}

		if (this.endSentinel) {
			observer.observe(this.endSentinel)
		}
	}

	private handleIntersectionChange(entries: IntersectionObserverEntry[]): void {
		entries.forEach((entry) => {
			const className =
				entry.target === this.startSentinel ? 'js-scroll-detect--start-overlay' : 'js-scroll-detect--end-overlay'

			this.element.classList.toggle(className, !entry.isIntersecting)
		})
	}
}

import TomSelect from 'tom-select/dist/esm/tom-select'
import TomSelectRemoveButton from 'tom-select/dist/esm/plugins/remove_button/plugin'
import TomSelectDropdownInput from 'tom-select/dist/esm/plugins/dropdown_input/plugin'
import TomSelectCheckboxOptions from 'tom-select/dist/esm/plugins/checkbox_options/plugin'
import { RecursivePartial, TomInput, TomOption, TomSettings } from 'tom-select/dist/types/types'
import { TPluginHash } from 'tom-select/dist/types/contrib/microplugin'
import Control from '@peckadesign/pd-naja/dist/utils/Control'
import { Spinner } from '@/js/App/Spinner'
import { escape_html } from 'tom-select/dist/types/utils'
import Tooltip from 'bootstrap/js/dist/tooltip'
import TooltipControl from '@/js/Controls/TooltipControl'

TomSelect.define('remove_button', TomSelectRemoveButton)
TomSelect.define('dropdown_input', TomSelectDropdownInput)
TomSelect.define('checkbox_options', TomSelectCheckboxOptions)

class TomSelectControl implements Control {
	private readonly spinnerHtml = Spinner({
		size: 30,
		textClass: 'h3',
		marginClass: 'my-2 mx-0 last:mb-0'
	}).outerHTML

	public initialize(context: Element | Document): void {
		const selects = context.querySelectorAll<HTMLSelectElement>('.js-select')

		selects.forEach((select) => this.initializeSelect(select))
	}

	private initializeSelect(select: HTMLSelectElement): void {
		const settings = this.getBaseSettings(select)

		this.setupMultipleSettings(settings, select)
		this.setupRemoteDataSettings(settings, select)

		new TomSelect(select as TomInput, settings)
	}

	private getBaseSettings(select: HTMLSelectElement): RecursivePartial<TomSettings> {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const control = this

		let dropdownParent: string | undefined = undefined

		if (select.closest('.table-responsive')) {
			dropdownParent = 'body'
		}

		return {
			plugins: {
				dropdown_input: {}
			},
			hideSelected: false,
			placeholder: select.dataset.filterPlaceholder || undefined,
			selectOnTab: !select.multiple,
			onInitialize: function (this: TomSelect) {
				const placeholderText = select.dataset.placeholder

				if (placeholderText) {
					const placeholder = document.createElement('span')
					placeholder.classList.add('ts-placeholder')
					placeholder.innerText = placeholderText
					this.control.append(placeholder)
				}

				control.initializeTooltips(this.control)
			},
			onDropdownOpen: this.initializeTooltips,
			onChange: function (this: TomSelect) {
				control.initializeTooltips(this.control)
			},
			render: {
				item: this.renderOption,
				option: this.renderOption
			},
			dropdownParent
		}
	}

	private setupMultipleSettings(settings: RecursivePartial<TomSettings>, select: HTMLSelectElement): void {
		if (!select.multiple) {
			return
		}

		const plugins = settings.plugins as TPluginHash

		plugins.checkbox_options = {}
		plugins.remove_button = {
			title: 'Remove this item',
			label: '❌'
		}
	}

	private setupRemoteDataSettings(settings: RecursivePartial<TomSettings>, select: HTMLSelectElement): void {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const control = this

		if (!select.dataset.remoteDataUrl) {
			return
		}

		settings.searchField = []

		const remoteDataUrl = select.dataset.remoteDataUrl
		const labelField = select.dataset.labelField
		const valueField = select.dataset.valueField
		const termParameter = select.dataset.termParameter || 'term'

		if (labelField) {
			settings.labelField = labelField
		}

		if (valueField) {
			settings.valueField = valueField
		}

		if (!settings.render) {
			settings.render = {}
		}

		settings.render.loading = () => this.spinnerHtml

		settings.load = function (this: TomSelect, query: string, callback: any) {
			const delimiter = remoteDataUrl.indexOf('?') === -1 ? '?' : '&'
			const url = remoteDataUrl + delimiter + termParameter + '=' + encodeURIComponent(query)

			this.clearOptions()
			fetch(url)
				.then((response) => response.json())
				.then((json) => {
					callback(json)
					control.initializeTooltips(this.dropdown)
				})
				.catch(() => callback())
		}
	}

	private initializeTooltips(context: HTMLElement): void {
		const tooltips = context.querySelectorAll('.ts-tooltip')

		tooltips.forEach((tooltip) => {
			Tooltip.getOrCreateInstance(tooltip, TooltipControl.tooltipOptions)
		})
	}

	private renderOption(this: TomSelect, data: TomOption, escape: typeof escape_html): string {
		let option = `<div>${escape(data[this.settings.labelField])}`

		if (data.tooltip) {
			const tooltipIcon = data.tooltipIcon || 'info'
			const tooltipIconColorClass = data.tooltipIconColor ? `text-${data.tooltipIconColor}` : ''

			option += ` <button type="button" class="ts-tooltip icon icon--${tooltipIcon} border-0 p-0 bg-transparent cursor-help ${tooltipIconColorClass}" title="${data.tooltip}"></button></div>`
		}

		option += '</div>'

		return option
	}
}

export default new TomSelectControl()

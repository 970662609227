import Control from '@peckadesign/pd-naja/dist/utils/Control'

class BottomStuck implements Control {
	public readonly selector = '.js-bottom-stuck'
	private observer: IntersectionObserver

	public constructor() {
		this.observer = new IntersectionObserver(this.handleIntersection.bind(this), { threshold: 1 })
	}

	public initialize(context: Element | Document): void {
		const bottomStuck = context.querySelectorAll(this.selector)

		bottomStuck.forEach((element) => this.observer.observe(element))
	}

	public destroy(context: Element): void {
		const bottomStuck = context.querySelectorAll(this.selector)

		bottomStuck.forEach((element) => this.observer.unobserve(element))
	}

	private handleIntersection(entries: IntersectionObserverEntry[]) {
		entries.forEach((entry: IntersectionObserverEntry) => {
			const force =
				(entry.rootBounds as DOMRectReadOnly).bottom - entry.boundingClientRect.bottom < 0 &&
				entry.intersectionRatio < 1

			entry.target.classList.toggle('is-stuck', force)
			entry.target.classList.toggle('not-stuck', !force)
		})
	}
}

export default new BottomStuck()

import Control from '@peckadesign/pd-naja/dist/utils/Control'
import { DependentSelectBox } from '@/js/App/DependentSelectBox'
import { TomInput } from 'tom-select/dist/types/types'

class DependentSelectBoxControl implements Control {
	public initialize(context: Element | Document): void {
		const dependentSelectBoxes = context.querySelectorAll<TomInput>('select[data-select-depends-on]')
		dependentSelectBoxes.forEach(function (element) {
			const dependsOnSelectId = element.dataset.selectDependsOn
			if (dependsOnSelectId === undefined) {
				throw new Error('Missing dependent select ID')
			}

			const dependsOnSelect = document.getElementById(dependsOnSelectId) as TomInput
			if (dependsOnSelect === null) {
				throw new Error('Dependent select by ID not found')
			}

			if (!element.tomselect || !dependsOnSelect.tomselect) {
				throw new Error('Select boxes are not tomselect instances')
			}
			return new DependentSelectBox(element.tomselect, dependsOnSelect.tomselect)
		})
	}
}

export default new DependentSelectBoxControl()

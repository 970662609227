import TooltipControl from '@/js/Controls/TooltipControl'
import Tooltip from 'bootstrap/js/dist/tooltip'

export default class ColorInput {
	private input: HTMLInputElement
	private clearButton?: Node

	private colorOnFocus?: string

	public constructor(element: Element, selector: string) {
		const input = element.querySelector(`${selector}__input`)
		const template = element.querySelector('template')

		if (!input || !template) {
			throw new Error('Invalid color input template.')
		}

		this.input = input as HTMLInputElement
		this.clearButton = template?.content?.firstElementChild?.cloneNode(true)
		this.clearButton?.addEventListener('click', this.clearInput.bind(this))

		this.input.addEventListener('focus', this.showColorPicker.bind(this))
		this.input.addEventListener('click', this.showColorPicker.bind(this))
		this.input.addEventListener('keydown', this.handleKeydown.bind(this))
		this.input.addEventListener('change', this.handleChange.bind(this))

		// Initialize with value from DOM
		this.initialize()
	}

	private initialize(): void {
		if (this.input.value) {
			this.changeToColor()
		} else {
			this.changeToText()
		}

		this.handleChange()
	}

	private handleKeydown(event: KeyboardEvent): void {
		switch (event.key) {
			case 'Escape':
				if (this.colorOnFocus === '') {
					this.clearInput()
				}
				break

			case 'Delete':
			case 'Backspace':
				this.clearInput()
				break

			case 'Enter':
				this.showColorPicker()
				break
		}
	}

	private changeToText(): void {
		this.input.type = 'text'
		this.input.value = ''
		this.input.readOnly = true
	}

	private changeToColor(): void {
		this.input.readOnly = false
		this.input.type = 'color'
	}

	private clearInput(event?: Event): void {
		event?.preventDefault()

		this.changeToText()
		this.input.dispatchEvent(new Event('change', { bubbles: true }))
	}

	private showColorPicker(event?: Event): void {
		// If the input is already `type="color"`, leave the native behaviour.
		if (this.input.type === 'color') {
			return
		}

		if (event && event.type === 'focus') {
			this.colorOnFocus = this.input.value
		}

		this.changeToColor()

		// `setTimeout` is necessary for Safari, otherwise the picker is shown and immediately hidden.
		setTimeout(() => {
			this.input.showPicker()
		}, 0)

		// When changed to `type="color"`, the value is automatically changed to `#000000`
		this.input.dispatchEvent(new Event('change', { bubbles: true }))
	}

	private handleChange(): void {
		this.input.dispatchEvent(new CustomEvent('validate', { bubbles: true }))

		this.input.value ? this.showClearButton() : this.hideClearButton()
	}

	private showClearButton(): void {
		if (!this.clearButton || this.clearButton.isConnected) {
			return
		}

		this.input.after(this.clearButton)
		Tooltip.getOrCreateInstance(this.clearButton as HTMLButtonElement, TooltipControl.tooltipOptions)
	}

	private hideClearButton(): void {
		if (!this.clearButton || !this.clearButton.isConnected) {
			return
		}

		Tooltip.getInstance(this.clearButton as HTMLButtonElement)?.hide()
		;(this.clearButton as HTMLButtonElement).remove()
	}
}
